<template>
  <section class="hero is-fullheight">
    <div class="hero-body">
      <div class="container">
        <div class="columns is-multiline is-centered">
          <div class="column is-4 card">
            <div class="field">
              <h1 class="title">Inicia Sesión</h1>
            </div>
            <div class="field">
               <label class="label">Correo electrónico</label>
              <p class="control">
                <input
                  v-model="email"
                  class="input"
                  type="email"
                  name="email"
                  placeholder="Email"
                  v-validate="'required|email'"
                >
              </p>
              <span class="is-error-input">{{ vverrors.first('email') }}</span>
            </div>
            <div class="field">
              <label class="label">Contraseña</label>
              <p class="control">
                <input
                  v-model="password"
                  v-validate="'required'"
                  class="input"
                  type="password"
                  placeholder="Password"
                  name="password"
                >
              </p>
              <span class="is-error-input">{{ vverrors.first('password') }}</span>
            </div>
            <div class="notification is-danger" v-if="error">
              {{message}}
            </div>
            <div class="field">
              <p class="control">
                <button
                  class="button is-cycle"
                  @click="login"
                  :class="{ 'is-loading': loading }"
                  :disabled="loading"
                  >
                  Iniciar sesión
                </button>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import api from '@/libs/api'
export default {
  name: 'Login',
  data () {
    return {
      email: null,
      password: null,
      loading: false,
      error: false,
      message: null
    }
  },
  methods: {
    async login () {
      this.error = false
      if (await this.$validator.validateAll()) {
        this.loading = true
        const userLogin = {
          email: this.email,
          password: this.password
        }
        const resp = await api.login(userLogin)
        if (resp.success) {
          localStorage.setItem('access-token', resp.token)
          localStorage.setItem('name', `${resp.name} ${resp.last_name}`)
          this.$router.replace('/')
        } else {
          this.error = true
          this.message = resp.message
        }
        this.loading = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.hero {
  background: #fafafa;
  .title {
    margin-bottom: 2rem;
  }
}
.field {
  .label {
    text-align: left;
  }
  margin-bottom: 1rem;
}
.button {
  margin-top: 2rem;
}
.is-cycle {
  background-color: #0019A8;
  color: #FFFFFF;
}
.is-cycle:hover {
  background-color: #0019A8;
  color: #FFFFFF;
  opacity: 0.8;
}
</style>
